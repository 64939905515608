import { render, staticRenderFns } from "./GlobalSearchPopup.vue?vue&type=template&id=41c7b521&scoped=true&"
import script from "./GlobalSearchPopup.vue?vue&type=script&lang=js&"
export * from "./GlobalSearchPopup.vue?vue&type=script&lang=js&"
import style0 from "./GlobalSearchPopup.vue?vue&type=style&index=0&id=41c7b521&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41c7b521",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiButton: require('/var/teamcity-builds/Omara_Staging_Builds_Frontend/components/UI/UiButton.vue').default})
